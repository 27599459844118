i<template>
	<section id="advertisementsStepPage">
		<article class="top-section">
			<div>
				<h1>Nieuwsbrief samenstellen</h1>
			</div>
			<div>
				<ul class="newsletter-steps">
					<router-link :to="{name: 'NewsItemStep'}" tag="li">
						<span>1</span>
						<div>Nieuwsberichten</div>
					</router-link>
					<router-link :to="{name: 'AdvertisementStep'}" tag="li">
						<span>2</span>
						<div>Advertentie</div>
					</router-link>
					<router-link :to="{name: 'CalendarItemStep'}" tag="li">
						<span>3</span>
						<div>Agenda items</div>
					</router-link>
				</ul>
				<router-link :to="{name: 'CalendarItemStep'}" tag="div" class="btn btn-primary">
					Verder naar Agenda
					<img src="@/assets/right-arrow.svg" height="20px" alt="">
				</router-link>
				<router-link :to="{name: 'NewsItemStep'}" id="backToNewsItemsBtn" tag="div" class="btn btn-transparant">
					<img src="@/assets/left-arrow-orange.svg" alt="">
					Terug naar nieuwsberichten
				</router-link>
			</div>
		</article>
		<article id="advertisementSelection">
			<div>
				<h2>Advertenties</h2>
				<ul id="advertisementList">
					<draggable v-model="advertisements" group="advertisements" @start="drag=true" @end="drag=false">
						<li v-for="advertisement in advertisements" :key="advertisement.id">
							{{advertisement.title}}
						</li>
					</draggable>
				</ul>
			</div>
			<div id="advertisementSelectedLists">
				<h2>Rechterkolom</h2>
				<ul class="sortable-list">
					<draggable v-model="selectedAdvertisementsRight" group="advertisements" @start="drag=true" @end="drag=false">
						<li v-for="advertisement in selectedAdvertisementsRight" :key="advertisement.id">
							{{advertisement.title}}
						</li>
					</draggable>
				</ul>
				<h2>Centrale kolom</h2>
				<ul class="sortable-list">
					<draggable v-model="selectedAdvertisementsCenter" group="advertisements" @start="drag=true" @end="drag=false">
						<li v-for="advertisement in selectedAdvertisementsCenter" :key="advertisement.id">
							{{advertisement.title}}
						</li>
					</draggable>
				</ul>
			</div>
		</article>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable'

export default {
	data () {
		return {
			advertisements: [],
			selectedAdvertisementsRight:[],
			selectedAdvertisementsCenter:[]
		}
	},
	components: {
			draggable,
	},
	watch: {
		selectedAdvertisementsCenter (val) {
			this.$store.commit('newsletter/setSelectedAdvertisementsCenter', val)
		},
		selectedAdvertisementsRight (val) {
			this.$store.commit('newsletter/setSelectedAdvertisementsRight', val)
		}
	},
	computed: {
	},
	created () {
		console.log(this.$store.state.newsletter.selectedAdvertisementsCenter)
		this.selectedAdvertisementsRight = this.$store.state.newsletter.selectedAdvertisementsRight;
		this.selectedAdvertisementsCenter = this.$store.state.newsletter.selectedAdvertisementsCenter;

		this.$store.dispatch('advertisement/fetchAdvertisements').then(advertisements => {
			let ids = [...this.selectedAdvertisementsRight, ...this.selectedAdvertisementsCenter].map(a => a.id)
			this.advertisements = advertisements.filter(a => !ids.includes(a.id))
		})
	},
	mounted () {
	},
	methods: {
		removeSelectedItem (itemId) {
			this.selectedAdvertisements = this.selectedAdvertisements.filter(s => s.id !== itemId)
		}
	}
}
</script>
<style lang="scss">
#app #advertisementsStepPage {
	height: 100%;
	padding: 30px;
	#backToNewsItemsBtn {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	> article {
		&.top-section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			h1 {
				margin-bottom: 40px;
			}
			.el-date-editor {
				max-width: 400px;
			}
		}
	}
	#advertisementSelection {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
		h2 {
			margin: 30px 0 10px;
		}
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			border-top: 2px solid #E04E39;
			li {
				background-color: white;
				cursor: move;
				border-bottom: 1px solid #DCDCDC;
				border-left: 1px solid #DCDCDC;
				border-right: 1px solid #DCDCDC;
				display: grid;
				grid-template-columns: 1fr 40px;
				padding: 12px 20px;
			}
		}
	}
}
</style>